import React, {
  useState,
  useEffect,
  useCallback
} from 'react'
import {
  Menu,
  Icon
} from 'antd'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import request from '../util/request'
import util from '../util/index'
import config from '../config'

const { SubMenu } = Menu;

const menuStyle = {
  borderRight: 0,
  height: '100vh',
  boxSizing: 'border-box',
  paddingBottom: '100px',
  overflow: 'auto'
}

function MenuBar() {

  const history = useHistory()
  const [openList, setOpenList] = useState([])
  const [menu, setMenu] = useState([])
  const [result, setResult] = useState([])

  useEffect(() => {

    if (config.headerCode === 'putuo') {
      request.get('/user/getCurrentUser').then(res => {
        return request(`/auth/getUserPer?userId=${res.data.data.id}`)
      }).then(res => {
        const result = res.data.data.map(item => {
          return {
            ...item,
            key: `${util.getSourcePath()}${item.page}`,
            page: `${util.getSourcePath()}${item.page}`
          }
        }).sort((a, b) => b.sort - a.sort)
        const menu = []

        result.forEach(item => {
          if (item.pid === 0) {
            menu.push({
              id: item.id,
              key: item.page,
              page: item.page,
              name: item.name,
              icon: item.icon,
              children: []
            })
          }
        })

        result.forEach(item => {
          if (item.pid !== 0) {
            const menuItem = menu.filter(menuItem => menuItem.id === item.pid)[0]
            menuItem.children.push({
              id: item.id,
              key: item.page,
              page: item.page,
              name: item.name,
              icon: item.icon
            })
          }
        })

        setMenu(menu)
        setResult(result)
      })
    } else {
      request.get('/auth/permList').then(res => {
        const result = res.data.data.map(item => {
          return {
            ...item,
            key: `${util.getSourcePath()}${item.page}`,
            page: `${util.getSourcePath()}${item.page}`
          }
        }).sort((a, b) => b.sort - a.sort)
        const menu = []

        result.forEach(item => {
          if (item.pid === 0) {
            menu.push({
              id: item.id,
              key: item.page,
              page: item.page,
              name: item.name,
              icon: item.icon,
              children: []
            })
          }
        })

        result.forEach(item => {
          if (item.pid !== 0) {
            const menuItem = menu.filter(menuItem => menuItem.id === item.pid)[0]
            menuItem.children.push({
              id: item.id,
              key: item.page,
              page: item.page,
              name: item.name,
              icon: item.icon
            })
          }
        })

        setMenu(menu)
        setResult(result)
      })
    }

  }, [])

  useEffect(() => {
    result.forEach(item => {
      if (item.page === history.location.pathname) {
        setOpenList([item.pid.toString()])
      }
    })
  }, [result, history])

  function navigateTo(item) {
    history.push(item.key)
  }

  function openChange(data) {
    setOpenList(data)
  }

  return (
    <Menu theme="dark"
      mode="inline"
      style={menuStyle}
      onOpenChange={openChange}
      openKeys={openList}
      selectedKeys={[history.location.pathname]}
      onClick={item => navigateTo(item)}
    >
      {
        menu.map(item => {
          if (item.children && item.children.length > 0) {
            return (
              <SubMenu key={item.id}
                title={
                  <span >
                    <Icon type={item.icon} />
                    {item.name}
                  </span>
                } >
                {
                  item.children.map(child => (
                    <Menu.Item key={child.key}>{child.name}</Menu.Item>)
                  )
                }
              </SubMenu>
            )
          }
          return <Menu.Item key={item.key} > {
            item.icon ? <Icon type={item.icon} /> : null}
            {item.name}
          </Menu.Item>
        })
      }
    </Menu>
  )

}

export default MenuBar