import React, { useEffect, useState } from 'react'
import { Layout, Icon } from 'antd'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import config from '../config'
import request from '../util/request'
import util from '../util/index'
import { deleteStorage } from '../util/localStorage'

console.log(config)
const { Header } = Layout

const Image = styled.img`
  height: 50px;
  position: relative;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  margin-left: 15px;
  font-size: 17px;
  font-weight: 500;
  color: #666;
  cursor: pointer;
  &:hover {
    color: #1890ff;
  }
`

const Welcome = styled.div`
  font-size: 15px;
  margin-right: 50px;
`

const Quit = styled.div`
  color: #1890ff;
  display: flex;
  align-items: center;
`

const QuitText = styled.span`
  margin-left: 5px;
  cursor: pointer;
`

const headStyle = {
  background: '#fff',
  padding: '0 30px 0 10px',
  position: 'fixed',
  width: '100%',
  zIndex: 100
}

function Index(props) {
  const { history } = props
  const [userName, setUserName] = useState('admin')

  function quit() {
    request.get('/user/logout').then(res => {
      // localStorage.setItem('token', '')
      deleteStorage(config.pathName, '')
      history.replace(`${util.getSourcePath()}/login`)
    })
  }
  function toHome() {
    if (config.customSDK.headerDisabled) {
      return
    }
    if (history.location.pathname !== `${util.getSourcePath()}/home`) {
      history.push(`${util.getSourcePath()}/home`)
    }
  }

  useEffect(() => {
    request.get('/user/getCurrentUser').then(res => {
      const result = res.data
      if (result.status === 0) {
        setUserName(result.data.username)
      }
    })
  }, [])

  return (
    <Header style={headStyle}>
      <Container>
        <HeaderItem>
          {config.headerLogo && <Image src={config.headerLogo} />}
          <Title onClick={() => toHome()}>{config.title}</Title>
        </HeaderItem>
        <HeaderItem>
          {userName && <Welcome>欢迎您，{userName}</Welcome>}
          <Quit>
            <Icon type="poweroff" />
            <QuitText onClick={() => quit()}>退出</QuitText>
          </Quit>
        </HeaderItem>
      </Container>
    </Header>
  )

}

export default withRouter(Index)
