import { convertToRaw } from 'draft-js'
import qs from 'qs'

const util = {
  editorStateToRichText(editorState) {
    const content = convertToRaw(editorState.getCurrentContent())

    const blockType = ['$h1$', '$h2$', '$h3$']

    const blocks = content.blocks
    const entityMap = content.entityMap
    const result = []

    blocks.forEach(item => {
      var inlineStyleRanges = item.inlineStyleRanges.slice(0)
      inlineStyleRanges.sort((a, b) => a.offset - b.offset)

      let firstIndex = 0

      let finalStr = ''

      if (item.type === 'atomic') {
        //这一行为图片时
        const atomicItem = entityMap[item.entityRanges[0].key]
        finalStr += (`${atomicItem.type}${atomicItem.data.src}${atomicItem.type}`)
        result.push(finalStr)
      } else if (blockType.indexOf(item.type) > -1) {
        //这一行为标题时
        finalStr += (`${item.type}${item.text}${item.type}`)
        result.push(finalStr)
      } else if (inlineStyleRanges.length > 0) {
        //这一行为行内样式时
        inlineStyleRanges.forEach(inlineItem => {
          finalStr += item.text.slice(firstIndex, inlineItem.offset)
          finalStr += (`${inlineItem.style}` + item.text.slice(inlineItem.offset, inlineItem.offset + inlineItem.length) + `${inlineItem.style}`)
          firstIndex = inlineItem.offset + inlineItem.length
        })
        if (item.text.length > firstIndex) {
          finalStr += item.text.slice(firstIndex, item.text.length)
        }
        result.push(finalStr)
      } else {
        //这一行为普通文字时
        result.push(item.text)
      }
    })

    return result
  },

  htmlTextToRichText(text = '') {
    return text.replace(/<(?:.|\n)*?>/gm, '')
      .replace(/<\?xml.*\?>\n/, '')
      .replace(/<\?xml.*\?>\n/, '')
      .replace(/<.*!doctype.*\>\n/, '')
      .replace(/<.*!DOCTYPE.*\>\n/, '').replace(/\r?\n+/g, '')
      .replace(/<!--.*?-->/ig, '')
      .replace(/\/\*.*?\*\//ig, '')
      .replace(/[ ]+</ig, '<')
      .replace(/&nbsp;/ig, '')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<')
      .replace(/&amp/g, '&')
      .replace(/&quot;/g, '\"')
  },

  flat(arr) {
    let res = []
    function merge(childArr) {
      for (let i = 0; i < childArr.length; i++) {
        if (Object.prototype.toString.call(childArr[i]) === '[object Array]') {
          merge(childArr[i])
        } else {
          res.push(childArr[i])
        }
      }
    }
    merge(arr)

    return res
  },

  richTextToArray(richText) {
    const paragraph = richText.split(/\#br\#|\$br\$/)
    const _this = this

    function deal(item) {

      let temp = item.split(/\#image\#|\$image\$/).map((data, index) => {
        return index % 2 === 0 ? data : {
          index: `img${index}`,
          type: 'img',
          data: data ? decodeURIComponent(data) : ''
        }
      })

      temp.forEach((data, index) => {
        if (typeof data === 'string' && (data.indexOf('$link$') > -1 || data.indexOf('#link#') > -1)) {
          temp[index] = data.split(/\#link\#|\$link\$/).map((cItem, cIndex) => {
            return cIndex % 2 === 0 ? cItem : {
              index: `link${cIndex}`,
              type: 'link',
              data: cItem
            }
          })
        }
      })

      temp = _this.flat(temp)

      temp.forEach((data, index) => {
        if (typeof data === 'string' && data.indexOf('$h1$') > -1) {
          temp[index] = data.split('$h1$').map((cItem, cIndex) => {
            return cIndex % 2 === 0 ? cItem : {
              index: `h1_${cIndex}`,
              type: 'h1',
              data: cItem
            }
          })
        }
      })

      temp = _this.flat(temp)

      temp.forEach((data, index) => {
        if (typeof data === 'string' && data.indexOf('$h2$') > -1) {
          temp[index] = data.split('$h2$').map((cItem, cIndex) => {
            return cIndex % 2 === 0 ? cItem : {
              index: `h2_${cIndex}`,
              type: 'h2',
              data: cItem
            }
          })
        }
      })

      temp = _this.flat(temp)

      temp.forEach((data, index) => {
        if (typeof data === 'string' && data.indexOf('$h3$') > -1) {
          temp[index] = data.split('$h3$').map((cItem, cIndex) => {
            return cIndex % 2 === 0 ? cItem : {
              index: `h3_${cIndex}`,
              type: 'h3',
              data: cItem
            }
          })
        }
      })

      temp = _this.flat(temp)

      temp.forEach((data, index) => {
        if (typeof data === 'string' && (data.indexOf('$phone$') > -1 || data.indexOf('#phone#') > -1)) {
          temp[index] = data.split(/\#phone\#|\$phone\$/).map((cItem, cIndex) => {
            return cIndex % 2 === 0 ? cItem : {
              index: `phone${cIndex}`,
              type: 'phone',
              data: cItem
            }
          })
        }
      })

      temp = _this.flat(temp)

      temp.forEach((data, index) => {
        if (typeof data === 'string' && data.indexOf('$strong$') > -1) {
          temp[index] = data.split('$strong$').map((cItem, cIndex) => {
            return cIndex % 2 === 0 ? cItem : {
              index: `strong${cIndex}`,
              type: 'strong',
              data: cItem
            }
          })
        }
      })

      temp = _this.flat(temp)

      temp.forEach((data, index) => {
        if (typeof data === 'string') {
          temp[index] = {
            index: `text${index}`,
            type: 'text',
            data: temp[index]
          }
        }
      })

      return temp.filter(_ => _)

    }

    const res = []

    paragraph.forEach(item => {
      res.push(deal(item))
    })

    return res
  },

  getSourcePath() {
    
    return window.location.pathname.split('/')[1] ? '/' + window.location.pathname.split('/')[1] : ''
  },

  urlSearch() {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {}
  }
}

export default util
