import React from 'react'
import config from '../../config'
import util from '../../util/index'
import { notification, Icon, Button } from 'antd'
import { guideInstance } from '../../util/request'
import emitter from './emitter'

let timer = null

function titleNotice() {
  if (!timer) {
    timer = setInterval(() => {
      if (document.title !== '提示') {
        document.title = '提示'
      } else {
        document.title = '您有新的订单！请及时查看'
      }
    }, 1000)
  }
}

const NoticeCloseButton = (props) => (
  <Button type="primary" onClick={() => {
    // notification.close(props.noticeKey)
    notification.destroy()
    props.history.push(`${util.getSourcePath()}/guide-order-list`)
  }}>
    前往订单列表
  </Button>
)

function audioPlay() {
  var audio = document.createElement('audio')
  audio.muted = false
  audio.autoplay = true
  audio.src = 'http://admin.wenhualvyou.net/voice.mp3'
  // document.body.appendChild(audio)
  audio.play()
}

// webSocket
var lockReconnect = false //避免ws重复连接
var ws = null   //判断当前浏览器是否支持WebSocket
var wsUrl = ''
var historyUrl = ''

function createWebSocket(url) {
  try {
    if ('WebSocket' in window) {
      ws = new WebSocket(url)
    }
    initEventHandle()
  } catch (e) {
    reconnect(url)
    console.log(e)
  }
}

function initEventHandle() {
  ws.onclose = function () {
    reconnect(wsUrl)
  }
  ws.onerror = function () {
    reconnect(wsUrl)
  }
  ws.onopen = function () {
    heartCheck.reset().start() // 心跳检测重置
  }
  ws.onmessage = function (event) {
    //如果获取到消息，心跳检测重置
    heartCheck.reset().start() //拿到任何消息都说明当前连接是正常的
    if (event.data != '连接成功') {
      if (document.hidden) {
        titleNotice()
      }

      const key = `open${Date.now()}`
      notification.open({
        message: '收到新订单',
        description: '您有一个新的导游订单，请前往订单列表页进行处理',
        duration: 0,
        icon: <Icon type="info-circle" style={{ color: '#108ee9' }} />,
        key,
        btn: <NoticeCloseButton noticeKey={key} history={historyUrl} />
      })
      audioPlay()
      emitter.emit('eventOrder')

    }
  }
}

function reconnect(url) {
  if (lockReconnect) return
  lockReconnect = true
  setTimeout(function () {
    // 没连接上会一直重连，设置延迟避免请求过多
    createWebSocket(url)
    lockReconnect = false
  }, 2000)
}

//心跳检测
var heartCheck = {
  timeout: 60000, // 1分钟发一次心跳
  timeoutObj: null,
  serverTimeoutObj: null,
  reset: function () {
    clearTimeout(this.timeoutObj)
    clearTimeout(this.serverTimeoutObj)
    return this
  },
  start: function () {
    var self = this
    this.timeoutObj = setTimeout(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      //onmessage拿到返回的心跳就说明连接正常
      self.serverTimeoutObj = setTimeout(function () {
        // 如果超过一定时间还没重置，说明后端主动断开了
        ws.close() // 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
      }, self.timeout)
    }, this.timeout)
  }
}

function init(history) {
  if (config.customSDK && config.customSDK['guideModule']) {
    const sid = Math.round(Math.random() * 1000000000000)

    guideInstance.get(`/aide/back/guide/config/webSocketUrl`).then(res => {
      console.log(res)

      wsUrl = `${res.data.data}/xxsdadmin/aide/back/guide/websocket/${sid}`
      historyUrl = history
      createWebSocket(wsUrl)

      document.addEventListener("visibilitychange", function () {
        if (!document.hidden) {
          document.title = config.title
          clearInterval(timer)
          timer = null
        }
      })

      window.onbeforeunload = () => {
        ws.close()
      }
    })
  }
}

export default {
  init
}