import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const Container = styled.div `
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function Loading() {
  return (
    <Container>
      <Spin />
    </Container>
  )
}

export default Loading
