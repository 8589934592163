import util from './util/index'
import bikeLogo from './img/bike_logo.jpg'
import logo from './img/logo.png'
import dayuLogo from './img/dayuLogo.png'

const source = util.getSourcePath()

const saasUrl = process.env.NODE_ENV === 'production' ? '/saas/admin' : '/common/saas/admin'

const configMap = {
  '/xixishidi': {
    title: '西溪湿地自游助手运营平台',
    // guideUrl: '/xxsdadmin',
    guideUrl: '/xxsdadmin',
    hotelUrl: '/xxsdadmin',
    // baseUrl: '/common/saas/admin',
    baseUrl: saasUrl,
    headerCode: 'XXSD',
    city: '杭州',
    mapZoom: 14,
    mapCenter: {
      lat: '30.26',
      lng: '120.065'
    },
    headerLogo: logo,
    customSDK: {
      'guideModule': true
    },
    pathName: 'xixishidi'
  },
  '/jiuhuashan': {
    title: '九华山自游助手运营平台',
    baseUrl: '/saas/admin',
    // baseUrl: '',
    headerCode: 'JIUHUASHAN',
    mapCenter: {
      lat: '30.483',
      lng: '117.815'
    },
    headerLogo: logo,
    mapZoom: 13,
    city: '九华山',
    customSDK: {},
    pathName: 'jiuhuashan'
  },
  '/putuo-bike': {
    title: '普陀山自行车管理平台',
    // baseUrl:'/manage',
    // mallUrl:'/manage',
    // baseUrl: 'https://admin.wenhualvyou.net/',
    baseUrl: 'https://bike.wenhualvyouai.net/manage',
    // baseUrl: 'http://10.110.50.42:8088/',
    // mallUrl: 'https://admin.wenhualvyou.net/',
    mallUrl: 'https://bike.wenhualvyouai.net/manage',
    // mallUrl: 'http://10.110.50.42:8088/',
    headerCode: '',
    mapCenter: {
      lat: '30.00',
      lng: '122.42'
    },
    customLogo: bikeLogo,
    mapZoom: 13,
    city: '普陀山',
    customSDK: {
      bike: true,
      headerDisabled: true
    },
    pathName: 'putuo-bike'
  },
  '/putuo': {
    title: '普陀山自游助手运营平台',
    baseUrl: 'http://192.168.0.105:8088',
    // baseUrl: 'http://47.110.151.199:8088',
    // mallUrl: 'http://192.168.0.152:8088',
    mallUrl: 'http://47.110.151.199:8088',
    headerCode: '',
    mapCenter: {
      lat: '30.01',
      lng: '122.395' 
    },
    mapZoom: 13,
    headerLogo: logo,
    city: '普陀山',
    customSDK: {
      offlineSell: true
    },
    pathName: 'putuo'
  },
  '/dayu': {
    title: '大余自游助手运营平台',
    baseUrl: '/saas/admin',
    // baseUrl: '',
    headerCode: 'DAYU',
    mapCenter: {
      lat: '25.413877',
      lng: '114.371334'
    },
    headerLogo: dayuLogo,
    mapZoom: 13,
    city: '大余县',
    customSDK: {},
    pathName: 'dayu'
  },
  '/yandangshan': {
    title: '雁荡山自游助手运营平台',
    baseUrl: saasUrl,
    hotelUrl: '/yandangshanadmin',
    // baseUrl: '',
    headerCode: 'YANDANGSHAN',
    mapCenter: {
      lat: '28.384',
      lng: '121.05'
    },
    headerLogo: logo,
    mapZoom: 13,
    city: '乐清',
    customSDK: {},
    pathName: 'yandangshan'
  },
  '/tianyige': {
    title: '天一阁自游助手运营平台',
    baseUrl: '/saas/admin',
    // baseUrl: '',
    headerCode: 'TIANYIGE',
    mapCenter: {
      lat: '29.876124',
      lng: '121.547624'
    },
    headerLogo: logo,
    mapZoom: 16,
    city: '宁波',
    customSDK: {},
    pathName: 'tianyige'
  },
  '/tiantai': {
    title: '天台山自游助手运营平台',
    baseUrl: '/saas/admin',
    // baseUrl: '',
    headerCode: 'TIANTAI',
    mapCenter: {
      lat: '29.1668',
      lng: '121.0353'
    },
    headerLogo: logo,
    mapZoom: 13,
    city: '天台',
    customSDK: {},
    pathName: 'tiantai'
  },
  '/guxianya': {
    title: '古县衙自游助手运营平台',
    baseUrl: 'http://47.110.151.199:8078',
    // baseUrl: '',
    headerCode: '',
    mapCenter: {
      lat: '29.370135',
      lng: '117.243916'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '景德镇',
    customSDK: {},
    pathName: 'guxianya'
  },
  '/sanheguzhen': {
    title: '三河古镇自游助手运营平台',
    // baseUrl: '/saas/admin',
    baseUrl: saasUrl,
    headerCode: 'SANHE',
    mapCenter: {
      lat: '31.517927',
      lng: '117.251306'
    },
    headerLogo: logo,
    mapZoom: 17,
    city: '合肥',
    customSDK: {},
    pathName: 'sanheguzhen'
  },
  '/xiandu': {
    title: '仙都自游助手运营平台',
    baseUrl: saasUrl,
    cardUrl: '/xianduadmin',
    // baseUrl: '',
    headerCode: 'XIANDU',
    mapCenter: {
      lat: '28.692351',
      lng: '120.13289'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '仙都',
    customSDK: {},
    pathName: 'xiandu'
  },
  '/xinghuacun': {
    title: '杏花村自游助手运营平台',
    // baseUrl: 'http://121.40.135.230:9096',
    baseUrl: saasUrl,
    // mallUrl: 'http://121.40.135.230:8001',
    mallUrl: '/xinghuacunadmin',
    // mallUrl: 'http://192.168.3.151:8001',
    // baseUrl: '',
    headerCode: 'XINHUACUN',
    mapCenter: {
      lat: '30.643863',
      lng: '117.4723'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '池州',
    customSDK: {

    },
    pathName: 'xinghuacun'
  },
  '/yiheyuan': {
    title: '颐和园自游助手运营平台',
    baseUrl: '/saas/admin',
    headerCode: 'YIHEYUAN',
    mapCenter: {
      lat: '39.999908',
      lng: '116.274716'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '北京',
    customSDK: {

    },
    pathName: 'yiheyuan'
  },
  '/changzhou': {
    title: '常州全域自游助手运营平台',
    baseUrl: '/saas/admin',
    headerCode: 'QYCHANGZHOU',
    mapCenter: {
      lat: '31.81681756',
      lng: '119.98013755'
    },
    mapZoom: 15,
    city: '常州',
    customSDK: {

    },
    pathName: 'changzhou'
  },
  '/zhongyuan': {
    title: '中源小镇自游助手运营后台',
    baseUrl: 'https://bsxzzyzs.lishicloud.com/admin',
    headerCode: '',
    mapCenter: {
      lat: '28.865081',
      lng: '114.996735'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '中原小镇',
    customSDK: {},
    pathName: 'zhongyuan'
  },
  '/tianchi': {
    title: '新疆天山天池景区',
    baseUrl: saasUrl,
    headerCode: 'TIANCHI',
    mapCenter: {
      lat: '43.887382',
      lng: '88.135728'
    },
    mapZoom: 15,
    city: '阜康市',
    customSDK: {

    },
    pathName: 'tianchi'
  },
  '/houying': {
    title: '后营村自游助手运营平台',
    baseUrl: saasUrl,
    headerCode: 'HOUYING',
    mapCenter: {
      lat: '30.179756',
      lng: '119.212722'
    },
    mapZoom: 15,
    city: '临安区',
    customSDK: {},
    pathName: 'houying'
  },
  '/daoxiang': {
    title: '稻香小镇自游助手运营平台',
    baseUrl: saasUrl,
    headerCode: 'DAOXIANG',
    mapCenter: {
      lat: '30.318568',
      lng: '119.944582'
    },
    mapZoom: 15,
    city: '余杭区',
    customSDK: {},
    pathName: 'daoxiang'
  },
  '/anqiu': {
    title: '安丘自游助手运营平台',
    baseUrl: saasUrl,
    headerCode: 'ANQIU',
    mapCenter: {
      lat: '36.483611',
      lng: '119.22503'
    },
    mapZoom: 15,
    city: '安丘市',
    customSDK: {},
    pathName: 'anqiu'
  },
  '/yanan': {
    title: '延安会务自游助手运营平台',
    baseUrl: saasUrl,
    headerCode: 'YANAN',
    mapCenter: {
      lat: '36.604946',
      lng: '109.489448'
    },
    mapZoom: 15,
    city: '延安市',
    customSDK: {},
    pathName: 'yanan'
  },
  '/eshan': {
    title: '莪山自游助手',
    baseUrl: saasUrl,
    headerCode: 'ESHAN',
    mapCenter: {
      lat: '29.801898',
      lng: '119.581535'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '杭州市',
    customSDK: {},
    pathName: 'eshan'
  },
  '/eshanxiang': {
    title: '莪山美丽城镇',
    baseUrl: saasUrl,
    headerCode: 'ESHANXIANG',
    mapCenter: {
      lat: '29.801898',
      lng: '119.581535'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '杭州市',
    customSDK: {},
    pathName: 'eshanxiang'
  },
  '/xinmingjiedao': {
    title: '新明街道小助手运营平台',
    baseUrl: saasUrl,
    headerCode: 'XINMING',
    mapCenter: {
      lat: '29.878519',
      lng: '121.620054'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '杭州市',
    customSDK: {},
    pathName: 'xinmingjiedao'
  },
  '/binhe': {
    title: '智慧滨河自游助手运营平台',
    baseUrl: saasUrl,
    headerCode: 'BINHE',
    mapCenter: {
      lat: '44.432392',
      lng: '84.909777'
    },
    headerLogo: logo,
    mapZoom: 15,
    city: '奎屯市',
    customSDK: {},
    pathName: 'binhe'
  }
}

const config = configMap[source] || {}

export default config