import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom"
import { Layout } from 'antd'
import MenuBar from './components/MenuBar'
import Header from './components/Header'
import Loading from './components/Loading'
import config from './config'
import './App.css'
import util from './util'
import request from './util/request'
import guideModule from './customSDK/guide-module'
import { getStorage } from './util/localStorage'

const { Content, Sider } = Layout
const Home = lazy(() => import('./pages/home/index'))
const Login = lazy(() => import('./pages/login/index'))
const AccountInfo = lazy(() => import('./pages/account-info/index'))
const AddOperation = lazy(() => import('./pages/add-operation/index'))
const AddQuestion = lazy(() => import('./pages/add-question/index'))
const AddUser = lazy(() => import('./pages/add-user/index'))
const ChangePassword = lazy(() => import('./pages/change-password/index'))
const ComplaintList = lazy(() => import('./pages/complaint-list/index'))
const NewQuestionList = lazy(() => import('./pages/new-question-list/index'))
const ScenicInfo = lazy(() => import('./pages/scenic-info/index'))
const ScenicEdit = lazy(() => import('./pages/scenic-edit/index'))
const HotelInfo = lazy(() => import('./pages/hotel-info/index'))
const HotelEdit = lazy(() => import('./pages/hotel-edit/index'))
const RestaurantInfo = lazy(() => import('./pages/restaurant-info/index'))
const RestaurantEdit = lazy(() => import('./pages/restaurant-edit/index'))
const SpecialtyInfo = lazy(() => import('./pages/specialty-info/index'))
const ActivityInfo = lazy(() => import('./pages/activity-info/index'))
const QuestionList = lazy(() => import('./pages/question-list/index'))
const NoSatList = lazy(() => import('./pages/nosat-list/index'))
const NoHitList = lazy(() => import('./pages/nohit-list/index'))
const UserList = lazy(() => import('./pages/user-list/index'))
const UserQuestion = lazy(() => import('./pages/user-question/index'))
const UseStatistics = lazy(() => import('./pages/use-statistics/index'))
const PassengerStatistics = lazy(() => import('./pages/passenger-statistics/index'))
const CharacterInfo = lazy(() => import('./pages/character-info/index'))
const NoticeEdit = lazy(() => import('./pages/notice-edit/index'))
const GuideList = lazy(() => import('./pages/guide/guide-list/index'))
const GuideEdit = lazy(() => import('./pages/guide/guide-edit/index'))
const GuideOrderList = lazy(() => import('./pages/guide/guide-order-list/index'))
const GuideOrderItem = lazy(() => import('./pages/guide/guide-order-item/index'))
const GuideOrderDetail = lazy(() => import('./pages/guide/guide-order-detail/index'))
const GuideSchedule = lazy(() => import('./pages/guide/guide-schedule/index'))
const GuideAppointment = lazy(() => import('./pages/guide/guide-appointment/index'))
const GuideChart = lazy(() => import('./pages/guide/guide-chart/index'))
const GoodEdit = lazy(() => import('./pages/shop-admin/good-edit/index'))
const GoodList = lazy(() => import('./pages/shop-admin/good-list/index'))
const OrderDetail = lazy(() => import('./pages/shop-admin/order-detail/index'))
const OrderList = lazy(() => import('./pages/shop-admin/order-list/index'))
const BannerManage = lazy(() => import('./pages/shop-admin/banner-manage/index'))
const EventTrack = lazy(() => import('./pages/event-tract/index'))
const UserAuditList = lazy(() => import('./pages/bike/user-audit-list/index'))
const ManageAuditList = lazy(() => import('./pages/bike/manage-audit-list/index'))
const PunishList = lazy(() => import('./pages/bike/punish-list/index'))
const AddBikeUser = lazy(() => import('./pages/bike/add-bike-user/index'))
const BikeApplyUser = lazy(() => import('./pages/bike/bike-apply-user/index'))
const UnitList = lazy(() => import('./pages/bike/unit-list/index'))
const AddUnit = lazy(() => import('./pages/bike/add-unit/index'))
const UnitPublishList = lazy(() => import('./pages/bike/unit-publish-list/index'))
const EditUnit = lazy(() => import('./pages/bike/edit-unit/index'))
const QRCodeInfo = lazy(() => import('./pages/qrcode-info/index'))
const GuideRefund = lazy(() => import('./pages/guide/guide-refund/index'))
const GuideRefundDetail = lazy(() => import('./pages/guide/guide-refund-detail/index'))
const GuideStatistics = lazy(() => import('./pages/guide/guide-statistics/index'))
const GuidePrice = lazy(() => import('./pages/guide/guide-price/index'))
const GuidePriceEdit = lazy(() => import('./pages/guide/guide-price-edit/index'))
const GuideLevel = lazy(() => import('./pages/guide/guide-level/index'))
const GuideLevelEdit = lazy(() => import('./pages/guide/guide-level-edit/index'))
const GuideAudit = lazy(() => import('./pages/guide/guide-audit/index'))
const AddMenu = lazy(() => import('./pages/add-menu'))
const AddNavigation = lazy(() => import('./pages/add-navigation'))
const GuideComment = lazy(() => import('./pages/guide/guide-comment/index'))
const GuideFinance = lazy(() => import('./pages/guide/guide-finance/index'))
const GuideOrderRefund = lazy(() => import('./pages/guide/guide-order-refund/index'))
const LifeFoodShopList = lazy(() => import('./pages/life-food/shop-list/index'))
const LifeFoodShopEdit = lazy(() => import('./pages/life-food/shop-edit/index'))
const LifeFoodMenuList = lazy(() => import('./pages/life-food/food-list/index'))
const LifeFoodMenuEdit = lazy(() => import('./pages/life-food/food-edit/index'))
const ParkInfo = lazy(() => import('./pages/park/park-info/index'))
const ParkEdit = lazy(() => import('./pages/park/park-edit/index'))
const ToiletInfo = lazy(() => import('./pages/toilet/toilet-info/index'))
const ToiletEdit = lazy(() => import('./pages/toilet/toilet-edit/index'))

// 一景一码
const ScenicQrcode = lazy(() => import('./pages/scenic-qrcode/scenic-qrcode-list/index'))
const ScenicQrcodeEdit = lazy(() => import('./pages/scenic-qrcode/scenic-qrcode-edit/index'))
const ScenicQrcodeMap = lazy(() => import('./pages/scenic-qrcode/scenic-qrcode-map/index'))

const NewHotelList = lazy(() => import('./pages/hotel/hotel-list/index'))
const NewHotelEdit = lazy(() => import('./pages/hotel/hotel-edit/index'))
const NewHotelAdd = lazy(() => import('./pages/hotel/hotel-add/index'))
const NewHotelDetail = lazy(() => import('./pages/hotel/hotel-detail/index'))
const NewRoomAdd = lazy(() => import('./pages/hotel/room-add/index'))
const NewRoomEdit = lazy(() => import('./pages/hotel/room-edit/index'))


// 莪山民宿
const OldHotelList = lazy(() => import('./pages/hotel-old/hotel-list/index'))
const OldHotelEdit = lazy(() => import('./pages/hotel-old/hotel-edit/index'))
const OldHotelAdd = lazy(() => import('./pages/hotel-old/hotel-add/index'))
const OldHotelDetail = lazy(() => import('./pages/hotel-old/hotel-detail/index'))

const OldGoodEdit = lazy(() => import('./pages/shop-admin-old/good-edit/index'))
const OldGoodList = lazy(() => import('./pages/shop-admin-old/good-list/index'))
const OldOrderDetail = lazy(() => import('./pages/shop-admin-old/order-detail/index'))
const OldOrderList = lazy(() => import('./pages/shop-admin-old/order-list/index'))
const OperateLog = lazy(() => import('./pages/operate-log/index'))

// 发现新鲜事
const NewsFlashList = lazy(() => import('./pages/find-something/news-list/index'))
const NewsFlashEdit = lazy(() => import('./pages/find-something/news-edit/index'))
const SomethingList = lazy(() => import('./pages/find-something/something-list/index'))
const SomethingEdit = lazy(() => import('./pages/find-something/something-edit/index'))
const PlayList = lazy(() => import('./pages/find-something/play-list/index'))
const PlayEdit = lazy(() => import('./pages/find-something/play-edit/index'))
const TripList = lazy(() => import('./pages/find-something/trip-list/index'))
const TripEdit = lazy(() => import('./pages/find-something/trip-edit/index'))
const FindBannerManage = lazy(() => import('./pages/find-something/banner-manage/index'))
// 仙都年卡
// const YearCardList = lazy(() => import('./pages/year-card/card-list/index'))
const SetCardInfo = lazy(() => import('./pages/year-card/card-info/index'))
const VipInfoList = lazy(() => import('./pages/year-card/vip-list/index'))
const TicketInfoList = lazy(() => import('./pages/year-card/ticket-list/index'))
const YearCardOrderList = lazy(() => import('./pages/year-card/order-list/index'))
const YearCardRealList = lazy(() => import('./pages/year-card/real-list/index'))
// 路线推荐
const TourLineList = lazy(() => import('./pages/tour-line/line-list/index'))
const TourLineEdit = lazy(() => import('./pages/tour-line/line-edit/index'))
const TourLineScenicList = lazy(() => import('./pages/tour-line/scenic-list/index'))
// 莪山资讯
const ClassifyList = lazy(() => import('./pages/information/classify-list/index'))
const InformationList = lazy(() => import('./pages/information/info-list/index'))
const InformationEdit = lazy(() => import('./pages/information/info-edit/index'))
const InformationBannerList = lazy(() => import('./pages/information/banner-list/index'))

function WaitingComponent(Component) {
  return () => (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  )
}

const sideStyle = {
  position: 'fixed',
  left: 0,
  height: '100vh'
}

function MainRouter({ location }) {
  const history = useHistory()

  useEffect(() => {
    // if (!localStorage.getItem('token')) {
    //   window.location.href = `${util.getSourcePath()}/login`
    // }
    // if (!location.pathname.includes(localStorage.getItem('pathName'))) {
    //   window.location.href = `${util.getSourcePath()}/login`
    // }
    if (!getStorage(config.pathName)) {
      window.location.href = `${util.getSourcePath()}/login`
    }
  }, [location.pathname])

  useEffect(() => {
    guideModule.init(history)
  }, [history])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout style={{ paddingTop: '64px' }}>
        <Sider width={200} style={sideStyle}>
          <MenuBar></MenuBar>
        </Sider>
        <Content style={{ margin: '0 16px 0 216px' }}>
          <Switch>
            <Route exact path="/:source/home" component={WaitingComponent(Home)} />
            <Route exact path="/:source/account-info" component={WaitingComponent(AccountInfo)} />
            <Route exact path="/:source/add-operation" component={WaitingComponent(AddOperation)} />
            <Route exact path="/:source/add-question" component={WaitingComponent(AddQuestion)} />
            <Route exact path="/:source/add-user" component={WaitingComponent(AddUser)} />
            <Route exact path="/:source/change-password" component={WaitingComponent(ChangePassword)} />
            <Route exact path="/:source/complaint-list" component={WaitingComponent(ComplaintList)} />
            <Route exact path="/:source/new-question-list" component={WaitingComponent(NewQuestionList)} />
            <Route exact path="/:source/scenic-info" component={WaitingComponent(ScenicInfo)} />
            <Route exact path="/:source/scenic-edit" component={WaitingComponent(ScenicEdit)} />
            <Route exact path="/:source/hotel-info" component={WaitingComponent(HotelInfo)} />
            <Route exact path="/:source/hotel-edit" component={WaitingComponent(HotelEdit)} />
            <Route exact path="/:source/restaurant-info" component={WaitingComponent(RestaurantInfo)} />
            <Route exact path="/:source/restaurant-edit" component={WaitingComponent(RestaurantEdit)} />
            <Route exact path="/:source/specialty-info" component={WaitingComponent(SpecialtyInfo)} />
            <Route exact path="/:source/activity-info" component={WaitingComponent(ActivityInfo)} />
            <Route exact path="/:source/question-list" component={WaitingComponent(QuestionList)} />
            <Route exact path="/:source/nosat-list" component={WaitingComponent(NoSatList)} />
            <Route exact path="/:source/nohit-list" component={WaitingComponent(NoHitList)} />
            <Route exact path="/:source/user-list" component={WaitingComponent(UserList)} />
            <Route exact path="/:source/user-question" component={WaitingComponent(UserQuestion)} />
            <Route exact path="/:source/use-statistics" component={WaitingComponent(UseStatistics)} />
            <Route exact path="/:source/passenger-statistics" component={WaitingComponent(PassengerStatistics)} />
            <Route exact path="/:source/character-info" component={WaitingComponent(CharacterInfo)} />
            <Route exact path="/:source/notice-edit" component={WaitingComponent(NoticeEdit)} />
            <Route exact path="/:source/guide-list" component={WaitingComponent(GuideList)} />
            <Route exact path="/:source/guide-edit" component={WaitingComponent(GuideEdit)} />
            <Route exact path="/:source/guide-order-list" component={WaitingComponent(GuideOrderList)} />
            <Route exact path="/:source/guide-order-item" component={WaitingComponent(GuideOrderItem)} />
            <Route exact path="/:source/guide-order-detail" component={WaitingComponent(GuideOrderDetail)} />
            <Route exact path="/:source/guide-schedule" component={WaitingComponent(GuideSchedule)} />
            <Route exact path="/:source/guide-appointment" component={WaitingComponent(GuideAppointment)} />
            <Route exact path="/:source/guide-chart" component={WaitingComponent(GuideChart)} />
            <Route exact path="/:source/shop-admin/good-edit" component={WaitingComponent(GoodEdit)} />
            <Route exact path="/:source/shop-admin/good-list" component={WaitingComponent(GoodList)} />
            <Route exact path="/:source/shop-admin/order-detail" component={WaitingComponent(OrderDetail)} />
            <Route exact path="/:source/shop-admin/order-list" component={WaitingComponent(OrderList)} />
            <Route exact path="/:source/shop-admin/banner-manage" component={WaitingComponent(BannerManage)} />
            <Route exact path="/:source/shop-admin/old-good-edit" component={WaitingComponent(OldGoodEdit)} />
            <Route exact path="/:source/shop-admin/old-good-list" component={WaitingComponent(OldGoodList)} />
            <Route exact path="/:source/shop-admin/old-order-detail" component={WaitingComponent(OldOrderDetail)} />
            <Route exact path="/:source/shop-admin/old-order-list" component={WaitingComponent(OldOrderList)} />
            <Route exact path="/:source/event-track" component={WaitingComponent(EventTrack)} />
            <Route exact path="/:source/bike/user-audit-list" component={WaitingComponent(UserAuditList)} />
            <Route exact path="/:source/bike/manage-audit-list" component={WaitingComponent(ManageAuditList)} />
            <Route exact path="/:source/bike/punish-list" component={WaitingComponent(PunishList)} />
            <Route exact path="/:source/bike/add-bike-user" component={WaitingComponent(AddBikeUser)} />
            <Route exact path="/:source/bike/bike-apply-user" component={WaitingComponent(BikeApplyUser)} />
            <Route exact path="/:source/bike/unit-list" component={WaitingComponent(UnitList)} />
            <Route exact path="/:source/bike/add-unit" component={WaitingComponent(AddUnit)} />
            <Route exact path="/:source/bike/edit-unit" component={WaitingComponent(EditUnit)} />
            <Route exact path="/:source/qrcode-info" component={WaitingComponent(QRCodeInfo)} />
            <Route exact path="/:source/guide-refund" component={WaitingComponent(GuideRefund)} />
            <Route exact path="/:source/guide-refund-detail" component={WaitingComponent(GuideRefundDetail)} />
            <Route exact path="/:source/guide-statistics" component={WaitingComponent(GuideStatistics)} />
            <Route exact path="/:source/guide-price" component={WaitingComponent(GuidePrice)} />
            <Route exact path="/:source/guide-price-edit" component={WaitingComponent(GuidePriceEdit)} />
            <Route exact path="/:source/guide-level" component={WaitingComponent(GuideLevel)} />
            <Route exact path="/:source/guide-level-edit" component={WaitingComponent(GuideLevelEdit)} />
            <Route exact path="/:source/guide-audit" component={WaitingComponent(GuideAudit)} />
            <Route exact path="/:source/add-menu" component={WaitingComponent(AddMenu)} />
            <Route exact path="/:source/add-navigation" component={WaitingComponent(AddNavigation)} />
            <Route exact path="/:source/guide-comment" component={WaitingComponent(GuideComment)} />
            <Route exact path="/:source/guide-finance" component={WaitingComponent(GuideFinance)} />
            <Route exact path="/:source/guide-order-refund" component={WaitingComponent(GuideOrderRefund)} />
            <Route exact path="/:source/life-food/shop-list" component={WaitingComponent(LifeFoodShopList)} />
            <Route exact path="/:source/life-food/shop-edit" component={WaitingComponent(LifeFoodShopEdit)} />
            <Route exact path="/:source/life-food/food-list" component={WaitingComponent(LifeFoodMenuList)} />
            <Route exact path="/:source/life-food/food-edit" component={WaitingComponent(LifeFoodMenuEdit)} />
            <Route exact path="/:source/scenic-qrcode" component={WaitingComponent(ScenicQrcode)} />
            <Route exact path="/:source/scenic-qrcode-edit" component={WaitingComponent(ScenicQrcodeEdit)} />
            <Route exact path="/:source/scenic-qrcode-map" component={WaitingComponent(ScenicQrcodeMap)} />
            <Route exact path="/:source/hotel/hotel-list" component={WaitingComponent(NewHotelList)} />
            <Route exact path="/:source/hotel/hotel-add" component={WaitingComponent(NewHotelAdd)} />
            <Route exact path="/:source/hotel/hotel-edit" component={WaitingComponent(NewHotelEdit)} />
            <Route exact path="/:source/hotel/hotel-detail" component={WaitingComponent(NewHotelDetail)} />
            <Route exact path="/:source/hotel/room-add" component={WaitingComponent(NewRoomAdd)} />
            <Route exact path="/:source/hotel/room-edit" component={WaitingComponent(NewRoomEdit)} />
            <Route exact path="/:source/hotel-admin/hotel-list" component={WaitingComponent(OldHotelList)} />
            <Route exact path="/:source/hotel-admin/hotel-add" component={WaitingComponent(OldHotelAdd)} />
            <Route exact path="/:source/hotel-admin/hotel-edit" component={WaitingComponent(OldHotelEdit)} />
            <Route exact path="/:source/hotel-admin/hotel-detail" component={WaitingComponent(OldHotelDetail)} />
            <Route exact path="/:source/operate-log" component={WaitingComponent(OperateLog)} />
            <Route exact path="/:source/find-something/news-list" component={WaitingComponent(NewsFlashList)} />
            <Route exact path="/:source/find-something/news-edit" component={WaitingComponent(NewsFlashEdit)} />
            <Route exact path="/:source/find-something/advisory-list" component={WaitingComponent(SomethingList)} />
            <Route exact path="/:source/find-something/advisory-edit" component={WaitingComponent(SomethingEdit)} />
            <Route exact path="/:source/find-something/play-list" component={WaitingComponent(PlayList)} />
            <Route exact path="/:source/find-something/play-edit" component={WaitingComponent(PlayEdit)} />
            <Route exact path="/:source/find-something/trip-list" component={WaitingComponent(TripList)} />
            <Route exact path="/:source/find-something/trip-edit" component={WaitingComponent(TripEdit)} />
            <Route exact path="/:source/find-something/banner-manage" component={WaitingComponent(FindBannerManage)} />
            <Route exact path="/:source/year-card/card-info" component={WaitingComponent(SetCardInfo)} />
            <Route exact path="/:source/year-card/vip-list" component={WaitingComponent(VipInfoList)} />
            <Route exact path="/:source/year-card/ticket-list" component={WaitingComponent(TicketInfoList)} />
            <Route exact path="/:source/year-card/order-list" component={WaitingComponent(YearCardOrderList)} />
            <Route exact path="/:source/year-card/real-list" component={WaitingComponent(YearCardRealList)} />
            <Route exact path="/:source/tour-line/line-list" component={WaitingComponent(TourLineList)} />
            <Route exact path="/:source/tour-line/line-edit" component={WaitingComponent(TourLineEdit)} />
            <Route exact path="/:source/tour-line/scenic-list" component={WaitingComponent(TourLineScenicList)} />
            <Route exact path="/:source/information/classify-list" component={WaitingComponent(ClassifyList)} />
            <Route exact path="/:source/information/info-list" component={WaitingComponent(InformationList)} />
            <Route exact path="/:source/information/info-edit" component={WaitingComponent(InformationEdit)} />
            <Route exact path="/:source/information/banner-list" component={WaitingComponent(InformationBannerList)} />
            <Route exact path="/:source/park-info" component={WaitingComponent(ParkInfo)} />
            <Route exact path="/:source/park-edit" component={WaitingComponent(ParkEdit)} />
            <Route exact path="/:source/toilet-info" component={WaitingComponent(ToiletInfo)} />
            <Route exact path="/:source/toilet-edit" component={WaitingComponent(ToiletEdit)} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

function BikeUnitPage() {

  useEffect(() => {
    if (!getStorage(config.pathName)) {
      window.location.href = `${util.getSourcePath()}/login`
    }
  }, [])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout style={{ paddingTop: '64px' }}>
        <Content style={{ margin: '0 16px 0 16px' }}>
          <Switch>
            <Route exact path="" component={WaitingComponent(UnitPublishList)} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

function App() {
  useEffect(() => {
    document.title = config.title
  }, [])

  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path="/:source/login" component={WaitingComponent(Login)} />
        <Route exact path="/:source/unit-publish-list" component={BikeUnitPage} />
        <Route exact path="" component={MainRouter} />
      </Switch>
    </BrowserRouter>
  )
}

export default App;
