const getStorage = (pathname) => {
  let tokenObj = localStorage.getItem('TOKEN_OBJ') || ''
  if (tokenObj) {
    tokenObj = JSON.parse(tokenObj)
  }
  return tokenObj[pathname] ? tokenObj[pathname] : ''
}

const setStorage = (pathname, tokenVal) => {
  let tokenObj = localStorage.getItem('TOKEN_OBJ') || ''
  if (tokenObj) {
    tokenObj = JSON.parse(tokenObj)
  } else {
    tokenObj = {}
  }
  tokenObj[pathname] = tokenVal
  tokenObj = JSON.stringify(tokenObj)
  localStorage.setItem('TOKEN_OBJ', tokenObj)
}

const deleteStorage = (pathname) => {
  let tokenObj = localStorage.getItem('TOKEN_OBJ') || ''
  if (tokenObj) {
    tokenObj = JSON.parse(tokenObj)
  }
  delete tokenObj[pathname]
  tokenObj = JSON.stringify(tokenObj)
  localStorage.setItem('TOKEN_OBJ', tokenObj)
}

export {
  getStorage,
  setStorage,
  deleteStorage
}