import axios from 'axios'
import config from '../config'
import util from '.'
import { getStorage, deleteStorage } from './localStorage'

const instance = axios.create({
  // baseURL: 'http://192.168.0.152:8088',
  // baseURL: config.baseUrl,
  // timeout: 30000,
  // headers: config.headerCode ? {
  //   'X-AUTH-TOKEN': localStorage.getItem('token') || '',
  //   'tenant': config.headerCode
  // } : {
  //   'X-AUTH-TOKEN': localStorage.getItem('token') || ''
  // }
  
  baseURL: config.baseUrl,
  timeout: 30000,
  headers: config.headerCode ? {
    'X-AUTH-TOKEN': getStorage(config.pathName),
    'tenant': config.headerCode
  } : {
    'X-AUTH-TOKEN': getStorage(config.pathName)
  }
})

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

instance.interceptors.response.use((response) => {
  if (response.data.status === 10000) {
    deleteStorage(config.pathName, '')
    window.location.href = `${util.getSourcePath()}/login`
  }
  return response
})

const mallInstance = axios.create({
  baseURL: config.mallUrl,
  timeout: 30000,
  headers: {
    'X-AUTH-TOKEN': getStorage(config.pathName),
  }
})
mallInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

mallInstance.interceptors.response.use((response) => {
  if (response.data.status === 10000) {
    deleteStorage(config.pathName, '')
    window.location.href = `${util.getSourcePath()}/login`
  }
  return response
})

const guideInstance = axios.create({
  baseURL: config.guideUrl,
  timeout: 30000,
  headers: {
    'X-AUTH-TOKEN': getStorage(config.pathName),
  }
})

guideInstance.interceptors.response.use((response) => {
  if (response.data.status === 10000) {
    deleteStorage(config.pathName, '')
    window.location.href = `${util.getSourcePath()}/login`
  }
  return response
})

const cardInstance = axios.create({
  baseURL: config.cardUrl,
  timeout: 30000,
  headers: {
    'X-AUTH-TOKEN': getStorage(config.pathName),
  }
})

cardInstance.interceptors.response.use((response) => {
  if (response.data.status === 10000) {
    deleteStorage(config.pathName, '')
    window.location.href = `${util.getSourcePath()}/login`
  }
  return response
})

const hotelInstance = axios.create({
  baseURL: config.headerCode!==''?config.hotelUrl:config.baseUrl,
  timeout: 30000,
  headers: {
    'X-AUTH-TOKEN': getStorage(config.pathName),
  }
  // headers: config.headerCode ? {
  //   'X-AUTH-TOKEN': localStorage.getItem('token') || '',
  //   'tenant': config.headerCode
  // } : {
  //   'X-AUTH-TOKEN': localStorage.getItem('token') || ''
  // }
})

hotelInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

hotelInstance.interceptors.response.use((response) => {
  if (response.data.status === 10000) {
    deleteStorage(config.pathName, '')
    window.location.href = `${util.getSourcePath()}/login`
  }
  return response
})


export {
  mallInstance,
  guideInstance,
  cardInstance,
  hotelInstance
}

export default instance